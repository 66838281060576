import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

//获取系统所有权限列表
export function getAllPermission() {
    return request({
      url: envInfo.bgApp.securityPath + '/permission/findAllPermission',
      method: 'get'
    })
}

//获取当前租户权限列表
export function getCurrentAllPermission(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/permission/findCurrentAllPermission',
    method: 'get',
    params
  })
}

//根据角色获取权限
export function _sys_getPermissionByRoleId(params) {
    return request({
      url: envInfo.bgApp.securityPath + '/sys/role/findPermissionByRoleId',
      method: 'get',
      params
    })
}

export function _tenant_getPermissionByRoleId(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/role/findPermissionByRoleId',
    method: 'get',
    params
  })
}

//保存授权
export function _sys_grantPermission(params) {
    return request({
      url: envInfo.bgApp.securityPath + '/sys/role/grantPermission',
      method: 'put',
      data: params
    })
}
export function _tenant_grantPermission(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/role/grantPermission',
    method: 'put',
    data: params
  })
}





