<template>
  <div class="indicator-edit-page">
    <div class="header-line">
      <div class="left-part">
        <div class="back-div" @click="routeBack">
          <img src="@/assets/images/go_back_icon.png" alt="" />
          <span>返回</span>
        </div>
        <div class="border-line"></div>
        <div class="name-div">
          <span v-if="!cardNameEdit" class="name-label">
            {{ form.cardName }}
          </span>
          <el-input
            class="name-input"
            ref="cardNameInput"
            v-else
            v-model="form.cardName"
            maxlength="30"
            :show-word-limit="true"
            @blur="changeEditState(false)"
            @keyup.native.enter="changeEditState(false)"
          ></el-input>
          <img
            class="edit-icon"
            src="@/assets/images/bms/edit_icon.png"
            alt=""
            @click="changeEditState(true)"
          />
        </div>
        <div class="update-time">
          最近保存时间: {{ updateTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}
        </div>
      </div>

      <div class="right-part">
        <r-button type="normal" @click="openCardLib">指标库</r-button>
        <r-button
          type="normal"
          @click="publishHandler"
          :loading="publishLoading"
          :disabled="errorMessage"
        >
          <i class="el-icon-position" style="margin-right: 4px"></i>
          发布</r-button
        >
        <r-button type="normal" plain @click="draftSave(true)">保存</r-button>
      </div>
    </div>
    <div class="main-part">
      <div class="code-part" id="codeDom">
        <ace-editor
          v-model="code"
          @init="editorInit"
          lang="html"
          theme="chrome"
          style="border: 1px solid #ebecec"
          height="100%"
          width="100%"
          @input="codeChange"
        >
        </ace-editor>
        <r-button class="run-button" plain @click="runHandler">运行</r-button>
      </div>
      <div class="drag-div" id="dragDiv"></div>
      <div class="preview-part" id="previewDiv" v-loading="runLoading">
        <div class="preview-inner">
          <SingleCard
            v-if="cardCode"
            :dataset="cardCode"
            @error="cardErrorHandler"
          />
        </div>
        <div class="error-message" v-if="errorMessage">
          <img src="@/assets/images/bms/error_message-icon.png" alt="" />
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <PublishDialog
      v-if="publishDialog.show"
      :dataset="publishDialog"
      :errorMessage="errorMessage"
      @submit="submitHandler"
      @close="
        () => {
          publishDialog.show = false;
        }
      "
    />
    <CardsLibComponent
      v-if="cardsLib.show"
      :dataset="cardsLib"
      @apply="applyHandler"
    />
  </div>
</template>

<script>
import {
  getCardDetailById,
  cardSave,
  getRecordVersion,
  getSharedDetailById,
} from "@/api/ruge/bms/indicatorManagement";
import SingleCard from "../../vlink/dashboardEdit/components/singleCard.vue";
import PublishDialog from "./components/publishDialog.vue";
import CardsLibComponent from "./components/cardsLibComponent.vue";
import AceEditor from "vue2-ace-editor";
import { cloneDeep } from "lodash";

export default {
  name: "indicator-edit-page",
  components: { AceEditor, SingleCard, PublishDialog, CardsLibComponent },
  data() {
    return {
      publishLoading: false,
      errorMessage: null,
      runLoading: false,
      code: null,
      cardCode: null,
      resouceCode: null,
      cardNameEdit: false,
      updateTime: new Date(),
      form: {
        cardName: "未命名",
      },
      publishDialog: {
        show: false,
        cardName: "",
      },
      cardsLib: {
        show: false,
      },
      saveInterval: {
        instance: null,
        step: 30 * 1000,
      },
      codeCounter: 0,
    };
  },
  created() {
    this.initDatas();
  },
  mounted() {
    this.bindEvent();
  },
  beforeDestroy() {
    this.clearInterval();
  },
  methods: {
    codeChange() {
      this.codeCounter++;
      if (this.codeCounter > 1 && !this.saveInterval.instance) {
        this.startSaveInterval();
      }
    },
    startSaveInterval() {
      this.clearInterval();
      this.saveInterval.instance = window.setInterval(() => {
        this.draftSave();
      }, this.saveInterval.step);
    },
    clearInterval() {
      if (this.saveInterval.instance) {
        window.clearInterval(this.saveInterval.instance);
      }
    },
    cardErrorHandler(error) {
      this.errorMessage = error;
    },
    applyHandler(cardId) {
      this.codeCounter = 0;
      this.clearInterval();
      // this.initDatas(cardId, this.runHandler);
      this.getSharedDetails(cardId);
      this.cardsLib.show = false;
    },
    openCardLib() {
      console.log("loadingloadingloadingloading");
      this.cardsLib.show = true;
    },
    draftSave(messageFlag, cb) {
      const { cardId } = this.$route.query;
      let params = {
        ...this.getDefaultCardItem(cardId),
        ...this.resouceCode,
        ...{ cardId },
      };
      params.cardName = this.form.cardName;
      params.cardConfig = this.code;
      params.cardStatus = "DRAFT";
      if (!cardId) {
        params.cardId = null;
      }
      console.log("params", params);
      cardSave(params).then((res) => {
        if (messageFlag) {
          this.$message.success("保存成功");
        }
        this.updateTime = new Date().getTime();
        if (!this.$route.query.cardId) {
          this.setUrlCardID(res.cardId);
        }
        if (cb) {
          cb();
        }
      });
    },
    submitHandler(datas, freshDatas) {
      const { cardId } = this.$route.query;
      this.form.cardName = datas.cardName;
      const params = {
        ...datas,
        ...{
          cardType: "bar", //暂时没用，默认给“bar"
          cardConfig: this.code,
          cardId,
          cardStatus: "PUBLISH",
        },
      };
      console.log("params-xxx", params);
      cardSave(params).then((res) => {
        this.publishDialog.show = false;
        this.$message.success("发布成功");
        this.updateTime = new Date().getTime();
        if (!this.$route.query.cardId) {
          this.setUrlCardID(res.cardId);
        }
        // 如果是发布的，更新完以后把publishDatas更新下
        if (freshDatas) {
          this.resouceCode = cloneDeep(res);
        }
      });
    },
    setUrlCardID(cardId) {
      // 添加查询参数 cardId
      const newQuery = { ...this.$route.query };
      newQuery.cardId = cardId;
      this.$router.replace({ query: newQuery });
      this.resouceCode.cardId = cardId;
    },
    publishHandler() {
      this.publishLoading = true;
      this.runHandler();
      setTimeout(() => {
        if (!this.errorMessage) {
          this.publishDialog.show = true;
          this.publishDialog.datas = { ...this.resouceCode, ...this.form };
        }
        this.publishLoading = false;
      }, 2000);
    },
    runHandler() {
      this.errorMessage = null;
      this.cardCode = null;
      this.runLoading = true;
      setTimeout(() => {
        this.resouceCode.cardConfig = this.code;
        this.cardCode = this.resouceCode;
        this.runLoading = false;
      }, 500);
    },
    routeBack() {
      if (this.codeCounter > 1) {
        this.draftSave(null, () => {
          this.$router.push({
            path: "/bms/indicatorManagement",
            query: {
              cardId: this.$route.query.cardId,
            },
          });
        });
      } else {
        this.$router.push({
          path: "/bms/indicatorManagement",
          query: {
            cardId: this.$route.query.cardId,
          },
        });
      }
    },
    editorInit(thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/mode/html"); //language
      require("brace/mode/python");
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);

      //thingEditor.setReadOnly(true);
    },
    changeEditState(state) {
      this.cardNameEdit = state;
      if (state) {
        this.$nextTick(() => {
          this.$refs.cardNameInput.focus();
          this.$refs.cardNameInput.select();
        });
      } else {
        this.draftSave();
      }
    },
    bindEvent() {
      const dragDom = document.getElementById("dragDiv");
      const codeDom = document.getElementById("codeDom");
      dragDom.onmousedown = (e) => {
        const elW = codeDom.clientWidth;
        const clientX = e.clientX;
        // const minWidth = 200;
        document.onmousemove = (event) => {
          event.preventDefault();
          const moveDistance = clientX - event.clientX;
          codeDom.style.width = `${elW - moveDistance}px`;
        };
        document.onmouseup = () => {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    },
    getSharedDetails(cardId) {
      getSharedDetailById({ cardId })
        .then((res) => {
          const { cardConfig, cardName, cardSize, cardType } = res;
          const rebuild = {
            ...this.resouceCode,
            ...{
              cardConfig,
              cardName,
              cardSize,
              cardType,
            },
          };
          this.cardCode = cloneDeep(rebuild);
          this.resouceCode = cloneDeep(rebuild);
          this.code = rebuild.cardConfig;
          this.form.cardName = rebuild.cardName;
        })
        .finally(() => {
          this.runHandler();
        });
    },
    initDatas(setId, cb) {
      const { cardId, type, versionId } = this.$route.query;
      if (versionId) {
        // 从版本记录的创建副本过来
        getRecordVersion({
          cardId,
          versionId,
        })
          .then((res) => {
            res.cardStatus = "DRAFT";
            this.cardCode = cloneDeep(res);
            this.resouceCode = cloneDeep(res);
            this.code = res.cardConfig;
            this.form.cardName = res.cardName;
          })
          .finally(() => {
            this.form.cardName += "-复制";
            // 移除查询参数 cardId、versionId
            const newQuery = { ...this.$route.query };
            delete newQuery.cardId;
            delete newQuery.versionId;
            this.$router.replace({ query: newQuery });
          });
      } else {
        const activeId = setId || cardId;
        if (!activeId) {
          this.initEmptyTemplate();
          return;
        }
        getCardDetailById({ cardId: activeId })
          .then((res) => {
            // 如果是从发布库选择的，把状态重置成草稿
            if (setId) {
              res.cardStatus = "DRAFT";
              res.groupId = this.resouceCode.groupId;
              res.groupName = this.resouceCode.groupName;
            }
            this.cardCode = cloneDeep(res);
            this.resouceCode = cloneDeep(res);
            this.code = res.cardConfig;
            this.form.cardName = res.cardName;
          })
          .finally(() => {
            if (type === "copy") {
              this.form.cardName += "-复制";
              // 移除查询参数 cardId
              const newQuery = { ...this.$route.query };
              delete newQuery.cardId;
              this.$router.replace({ query: newQuery });
            }
            if (cb) {
              cb();
            }
          });
      }
    },
    initEmptyTemplate() {
      const templateString = require("./codeTemplate.js");
      const { groupId } = this.$route.query;
      this.code = templateString.default;
      this.resouceCode = this.getDefaultCardItem(
        null,
        templateString.default,
        groupId
      );
      this.runHandler();
    },
    getDefaultCardItem(cardId, cardConfig, groupId) {
      return {
        cardConfig,
        groupId: groupId || "-1",
        cardSize: '{"mode":1}',
        isShared: "N",
        cardType: "bar", //暂时没用，默认给“bar"
        cardId,
        cardStatus: "DRAFT",
        permissionType: null,
        permissionList: "[]",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.indicator-edit-page {
  background: #ffffff;
  height: 100vh;
  .ace_editor {
    background: #f7f8fa;
  }
  .header-line {
    height: 70px;
    background: #f7f8fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-bottom: 1px solid #e3e8ee;
    .left-part {
      display: flex;
      align-items: center;
      .back-div {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: #2a61ff;
        img {
          margin-right: 4px;
          position: relative;
          top: -1px;
        }
        span {
          font-weight: 600;
        }
      }
      .border-line {
        width: 1px;
        height: 12px;
        background: #cbdbe9;
        margin: 0 10px;
      }
      .name-div {
        display: flex;
        align-items: center;
        .name-label {
          display: inline-block;
          max-width: 375px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
          font-size: 18px;
          color: #252d3d;
        }
        .edit-icon {
          cursor: pointer;
          margin-left: 2px;
        }
        .name-input {
          width: 300px;
        }
      }
      .update-time {
        margin-left: 25px;
        font-weight: 400;
        font-size: 14px;
        color: #5d687c;
      }
    }
  }
  .main-part {
    width: 100%;
    height: calc(100% - 70px);
    padding: 20px;
    display: flex;

    .code-part {
      min-width: 200px;
      width: 480px;
      background: #f7f8fa;
      position: relative;
      .run-button {
        position: absolute;
        right: 20px;
        bottom: 20px;
      }
    }
    .drag-div {
      width: 20px;
      background: #00000000;
      cursor: e-resize;
    }
    .preview-part {
      flex: 1;
      min-width: 200px;
      align-items: center;
      background: #f7f8fa;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .preview-inner {
        min-width: 50%;
      }
      .error-message {
        position: absolute;
        bottom: 20px;
        left: 20px;
        height: 42px;
        display: flex;
        align-items: center;
        width: calc(100% - 40px);
        color: #ff0000;
        padding: 0 20px;
        background: #fff7f6;
        border-radius: 10px;
        border: 1px solid rgba(252, 122, 66, 0.3);
        img {
          margin-right: 6px;
        }
      }
    }
  }
}
</style>