<template>
  <div class="card-preview-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="closeHandler"
      width="1000px"
      title="预览"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      append-to-body
    >
      <div
        class="card-preview-container"
        :style="{
          width: widthRender(dataset.datas),
        }"
      >
        <SingleCard v-if="dataset.show" :dataset="dataset.datas" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SingleCard from "../../../vlink/dashboardEdit/components/singleCard.vue";
export default {
  name: "card-preview-dialog",
  components: {
    SingleCard,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
          datas: {},
        };
      },
    },
  },
  methods: {
    closeHandler() {
      this.$emit("close");
    },
    widthRender(datas) {
      const { cardSize } = datas || {};
      if (cardSize) {
        const parsedSize = JSON.parse(cardSize);
        return `${parsedSize.mode * 33}%`;
      }
      return "100%";
    },
  },
};
</script>

<style lang="less" >
.card-preview-container {
  // border: 1px solid #ccc;
  margin-bottom: 30px;
  border-radius: 5px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  overflow: auto;
}
</style>