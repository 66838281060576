import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/////////////======系统管理员
//获取租户角色集合
export function _sys_getRoleList(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/role/list/all',
    method: 'get',
    params
  })
}

//获取角色分页集合
export function _sys_getRolePage(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/role/page/all',
    method: 'get',
    params
  })
}

//保存授权
export function _sys_grantRole(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/role/createRoleRelation',
    method: 'post',
    data: params
  })
}

//获取当前租户用户角色集
export function _sys_getRoleListByTenant(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/role/find/role',
    method: 'get',
    params
  })
}

//保存角色
export function _sys_saveRole(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/role/create',
    method: 'post',
    data: params
  })
}

//更新角色
export function _sys_updateRole(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/role/update',
    method: 'put',
    data: params
  })
}

//删除角色
export function _sys_deleteRole(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/sys/role/deleteRole',
    method: 'delete',
    data: params
  })
}

///////////////============租户管理员
//获取租户角色集合
export function _tenant_getRoleList(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/role/list/all',
    method: 'get',
    params
  })
}

//获取角色分页集合
export function _tenant_getRolePage(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/role/page/all',
    method: 'get',
    params
  })
}

//保存授权
export function _tenant_grantRole(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/role/createRoleRelation',
    method: 'post',
    data: params
  })
}

//获取当前租户用户角色集
export function _tenant_getRoleListByTenant(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/role/find/role',
    method: 'get',
    params
  })
}

//保存角色
export function _tenant_saveRole(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/role/create',
    method: 'post',
    data: params
  })
}

//更新角色
export function _tenant_updateRole(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/role/update',
    method: 'put',
    data: params
  })
}

//删除角色
export function _tenant_deleteRole(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/tenant/role/deleteRole',
    method: 'delete',
    data: params
  })
}

//申请权限
export function applyPermission(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/permission/apply/submit',
    method: 'post',
    data: params
  })
}

//审批申请权限电子流
export function approvePermission(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/permission/apply/approve',
    method: 'post',
    data: params
  })
}

//权限申请详情
export function roleApproveDetail(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/permission/apply/detail',
    method: 'get',
    params
  })
}

//重新提交
export function reApplyPermission(params) {
  return request({
    url: envInfo.bgApp.securityPath + '/permission/apply/reSubmit',
    method: 'post',
    data: params
  })
}

