<template>
  <div class="indicator-pushlish-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="closeHandler"
      width="600px"
      title="发布"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="publishForm"
        :rules="publishFormRule"
        label-position="top"
        ref="publishForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="指标名称" prop="cardName">
          <el-input
            maxlength="30"
            :show-word-limit="true"
            v-model="publishForm.cardName"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限" prop="permissionType">
          <el-select
            class="permission-input"
            v-model="publishForm.permissionType"
            placeholder="请选择"
            clearable
            @change="permissionTypeChange"
          >
            <el-option
              v-for="item in permissionSelectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-cascader
            v-show="publishForm.permissionType === 'point'"
            style="width: 100%"
            v-model="publishForm.permissionList"
            clearable
            :placeholder="$t('commons.selectPlease')"
            :options="permissionOptions"
            :props="{ multiple: true }"
          >
          </el-cascader>
          <el-select
            v-show="publishForm.permissionType === 'role'"
            style="width: 100%"
            multiple
            v-model="publishForm.permissionList"
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分组" prop="groupId">
          <el-select
            style="width: 100%"
            v-model="publishForm.groupId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in groupOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="尺寸" prop="cardSize">
          <el-select
            style="width: 100%"
            v-model="publishForm.cardSize"
            placeholder="请选择"
          >
            <el-option
              v-for="item in sizeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="共享" prop="isShared">
          <el-select
            style="width: 100%"
            v-model="publishForm.isShared"
            placeholder="请选择"
          >
            <el-option
              v-for="item in shareOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" prop="publishDescription">
          <el-input
            placeholder="请输入发布描述（500字内）"
            :show-word-limit="true"
            :rows="2"
            type="textarea"
            maxlength="500"
            v-model="publishForm.publishDescription"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="button-line">
        <r-button type="cancel" class="cancel-button" @click="closeHandler"
          >取消</r-button
        >
        <el-popover
          placement="top"
          title=""
          width=""
          trigger="manual"
          content="程序报错，请验证通过后再进行发布"
          v-model="errorMessage"
          popper-class="popover-error-message"
        >
          <r-button
            slot="reference"
            plain
            :disabled="errorMessage"
            @click="submitHandler"
            >发布</r-button
          >
        </el-popover>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAllPermission } from "@/api/ruge/security/permission";
import { _tenant_getRolePage } from "@/api/ruge/security/role";
import { permissionStructTree } from "@/utils/tree";
import { getGroupList } from "@/api/ruge/bms/indicatorManagement";
import { cloneDeep } from "lodash";

export default {
  name: "indicator-pushlish-dialog",
  data() {
    return {
      submitLoading: false,
      groupOptions: [],
      permissionOptions: [],
      roleOptions: [],
      sizeOptions: [
        {
          label: "小",
          value: 1,
        },
        {
          label: "中",
          value: 2,
        },
        {
          label: "大",
          value: 3,
        },
      ],
      shareOptions: [
        {
          label: "是",
          value: "Y",
        },
        {
          label: "否",
          value: "N",
        },
      ],
      permissionSelectOptions: [
        {
          label: "角色",
          value: "role",
        },
        {
          label: "权限点",
          value: "point",
        },
      ],
      publishForm: {
        cardName: null,
        permissionType: null, // role | point
        permissionList: [],
        groupId: "-1",
        cardSize: 1,
        isShared: "N",
        publishDescription: null,
      },
      publishFormRule: {
        cardName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请输入指标名称",
          },
        ],
      },
    };
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
    errorMessage: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(dateset) {
        if (dateset.datas) {
          this.displayDatas(dateset.datas);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    displayDatas(datas) {
      const {
        cardName,
        permissionType,
        permissionList,
        groupId,
        isShared,
        cardSize,
        coverPicture,
      } = datas;
      this.publishForm.cardName = cardName;
      this.publishForm.permissionType = permissionType;
      this.publishForm.permissionList =
        permissionType === "point"
          ? JSON.parse(permissionList).map((item) => {
              return [undefined, undefined, item];
            })
          : JSON.parse(permissionList);
      this.publishForm.groupId = groupId;
      this.publishForm.isShared = isShared;
      this.publishForm.coverPicture = coverPicture;
      this.publishForm.cardSize = JSON.parse(cardSize).mode;
    },
    submitHandler() {
      this.$refs.publishForm.validate((valid) => {
        if (valid) {
          const params = this.buildParams();
          this.$emit("submit", params, true);
        }
      });
    },
    buildParams() {
      let tempParams = cloneDeep(this.publishForm);
      tempParams.cardSize = JSON.stringify({
        mode: tempParams.cardSize,
      });
      tempParams.permissionList =
        tempParams.permissionType === "point"
          ? JSON.stringify(
              tempParams.permissionList.map((item) => {
                return item.join("");
              })
            )
          : JSON.stringify(tempParams.permissionList);
      return tempParams;
    },
    initDatas() {
      //图标下拉框加载
      getAllPermission().then((resp) => {
        let cascadeList = null;
        cascadeList = permissionStructTree(resp, this);

        //将第三级的空子节点 children : []设置为 children : undefined
        cascadeList.forEach(function (obj) {
          obj.children.forEach(function (msg) {
            msg.children.forEach(function (msg) {
              msg.children = undefined;
            });
          });
        });
        this.permissionOptions = cascadeList;
        console.log("this.permissionOptions", this.permissionOptions);
      });

      _tenant_getRolePage({
        current: 1,
        rowCount: 100,
      }).then((res) => {
        if (res && res.rows && res.rows.length) {
          this.roleOptions = res.rows.map((item) => {
            return {
              label: item.roleName,
              value: item.roleId,
            };
          });
        }
      });

      getGroupList().then((res) => {
        const defaultLine = [
          {
            groupName: "默认",
            groupId: "-1",
          },
        ];
        this.groupOptions = defaultLine.concat(res).map((item) => {
          return {
            value: item.groupId,
            label: item.groupName,
          };
        });
      });
    },
    closeHandler() {
      this.$emit("close");
    },
    permissionTypeChange() {
      this.publishForm.permissionList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.indicator-pushlish-dialog {
  .button-line {
    text-align: right;
    margin: 15px 0;
    .cancel-button {
      margin-right: 15px;
    }
  }
  .permission-input {
    width: 100%;
    margin-bottom: 12px;
  }
}
</style>
<style>
.popover-error-message {
  color: #ff0000;
}
</style>