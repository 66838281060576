<template>
  <div class="cards-lib-component">
    <el-drawer title="指标库" :size="392" :visible.sync="dataset.show">
      <div class="filter-line">
        <el-input
          suffix-icon="el-icon-search"
          v-model="searchKey"
          clearable
          @input="filterHandler"
        ></el-input>
      </div>
      <div class="card-list">
        <div v-for="item in cardList" :key="item.cardId">
          <div v-if="item.show" class="single-card">
            <img class="cover-img" :src="imgUrlRender(item)" alt="" />
            <div class="right-part">
              <div>
                <div class="label-line">
                  {{ item.cardName }}
                </div>
                <div class="name-line">
                  <span>{{ item.sharedUserName }}</span>
                  <span>{{
                    item.sharedDate | dateFormat("YYYY-MM-DD HH:mm:ss")
                  }}</span>
                </div>
              </div>
              <div class="button-line">
                <r-button mini @click="cardPreview(item)">预览</r-button>
                <r-button plain mini @click="applyHandler(item)">应用</r-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 预览弹框 -->
    <PreviewDialog
      :dataset="previewDialogDatas"
      @close="
        () => {
          previewDialogDatas.show = false;
        }
      "
    />
  </div>
</template>

<script>
import { getSharedList } from "@/api/ruge/bms/indicatorManagement";
import { envInfo } from "@/constants/envInfo";
import PreviewDialog from "../../indicatorManagement/components/previewDialog.vue";

export default {
  name: "cards-lib-component",
  components: {
    PreviewDialog,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  data() {
    return {
      searchKey: null,
      cardList: [],
      previewDialogDatas: {
        show: false,
        datas: {},
      },
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    cardPreview(item) {
      this.previewDialogDatas.show = true;
      this.previewDialogDatas.datas = item;
    },
    filterHandler() {
      const currentKey = this.searchKey.trim().toLowerCase();
      this.cardList.forEach((item) => {
        item.show = item.cardName.toLowerCase().includes(currentKey);
      });
    },
    initDatas() {
      getSharedList().then((res) => {
        this.cardList = res.map((item) => {
          item.show = true;
          return item;
        });
      });
    },
    imgUrlRender({ coverPicture, cardType }) {
      if (coverPicture) {
        if ([1, 2, 3].includes(coverPicture)) {
          return require(`../../../../../assets/images/bms/card_example_${coverPicture}.png`);
        }
        return `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${coverPicture}`;
      } else {
        const fileId = { bar: 1, pie: 2, line: 3 }[cardType];
        return require(`../../../../../assets/images/bms/card_example_${fileId}.png`);
      }
    },
    applyHandler(item) {
      this.$confirm(
        `应用该指标将会覆盖当前编辑内容，是否应用？`,
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$emit("apply", item.cardId);
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.cards-lib-component {
  .filter-line {
    padding: 0 20px;
    margin: 15px 0;
  }
  .card-list {
    height: calc(100vh - 127px);
    overflow: auto;
    .single-card {
      width: 352px;
      height: 102px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #eef2f6;
      margin: 12px auto;
      display: flex;
      padding: 10px;
      .cover-img {
        width: 150px;
        height: 82px;
      }
      .right-part {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-left: 10px;
        justify-content: space-between;
        .label-line {
          width: 168px;
          font-weight: 600;
          font-size: 12px;
          color: #252d3d;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 14px;
        }
        .name-line {
          margin-top: 10px;
          font-weight: 400;
          font-size: 10px;
          color: #a7b4c7;
          span + span {
            margin-left: 8px;
          }
        }
        .button-line {
          text-align: right;
        }
      }
    }
  }
}
</style>