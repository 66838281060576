export default `<template>
<div class="my-component">
  <img :src="$customIcons['default-example-icon']" >
  <div class="message-line">
    {{ message }}    
  </div>
</div>
</template>

<script>
export default {
name: "my-component",
data() {
  return {
    message: "这是默认指标样式",
  };
},
methods: {
    /**
     * 入口函数，名称必须为：initCharts
     */
    initCharts() {

    },
  },
};
</script>

<style lang="less" scoped>
  .my-component {
    color: #ff0000;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 5px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .message-line {
      font-weight: 400;
      font-size: 16px;
      color: #B5BECE;
      margin: 20px 0;
    }
  }
</style>
`;
